define("ember-validated-form/components/validated-button/button", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <button
    class={{class-list
      (if
        (macroCondition (macroGetOwnConfig "isUikit"))
        (class-list
          "uk-button"
          (if (eq @type "submit") "uk-button-primary" "uk-button-default")
        )
      )
      (if
        (macroCondition (macroGetOwnConfig "isBootstrap"))
        (class-list
          "btn"
          (if (eq @type "submit") "btn-primary" "btn-default")
          (if @loading "loading")
        )
      )
    }}
    type={{@type}}
    disabled={{@disabled}}
    {{on "click" @onClick}}
    ...attributes
  >
    {{#if (has-block)}}{{~yield~}}{{else}}{{~@label~}}{{/if}}
  </button>
  */
  {
    "id": "3D004329",
    "block": "[[[11,\"button\"],[16,0,[28,[37,0],[[27],[27]],null]],[16,4,[30,1]],[16,\"disabled\",[30,2]],[17,3],[4,[38,1],[\"click\",[30,4]],null],[12],[1,\"\\n  \"],[41,[48,[30,6]],[[[18,6,null]],[]],[[[1,[30,5]]],[]]],[1,\"\\n\"],[13]],[\"@type\",\"@disabled\",\"&attrs\",\"@onClick\",\"@label\",\"&default\"],false,[\"class-list\",\"on\",\"if\",\"has-block\",\"yield\"]]",
    "moduleName": "ember-validated-form/components/validated-button/button.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});