define("ember-select/templates/components/select-dropdown-option", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "F2K75C4Q",
    "block": "[[[1,[30,1,[\"name\"]]],[1,\"\\n\"]],[\"@model\"],false,[]]",
    "moduleName": "ember-select/templates/components/select-dropdown-option.hbs",
    "isStrictMode": false
  });
});