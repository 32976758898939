define("ember-validated-form/components/validated-input/types/checkbox", ["exports", "@ember/component", "@ember/object", "@glimmer/component", "@ember/template-factory"], function (_exports, _component, _object, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if (macroCondition (macroGetOwnConfig "isUikit"))}}
    <@labelComponent
      class="{{if @isValid 'uk-text-success'}} {{if @isInvalid 'uk-text-danger'}}"
    >
      <input
        class="uk-checkbox uk-margin-small-right"
        type="checkbox"
        name={{@name}}
        id={{@inputId}}
        disabled={{@disabled}}
        checked={{@value}}
        {{on "input" this.onUpdate}}
        ...attributes
      />
    </@labelComponent>
  {{else if (macroCondition (macroGetOwnConfig "isBootstrap"))}}
    <div class="custom-control custom-checkbox">
      <input
        class="custom-control-input
          {{if @isValid 'is-valid'}}
          {{if @isInvalid 'is-invalid'}}"
        type="checkbox"
        name={{@name}}
        id={{@inputId}}
        disabled={{@disabled}}
        checked={{@value}}
        {{on "input" this.onUpdate}}
        ...attributes
      />
      <@labelComponent class="custom-control-label" />
    </div>
  {{else}}
    <input
      type="checkbox"
      name={{@name}}
      id={{@inputId}}
      disabled={{@disabled}}
      checked={{@value}}
      {{on "input" this.onUpdate}}
      ...attributes
    />
    <@labelComponent />
  {{/if}}
  */
  {
    "id": "zSWiZJuc",
    "block": "[[[1,\"  \"],[11,\"input\"],[24,4,\"checkbox\"],[16,3,[30,1]],[16,1,[30,2]],[16,\"disabled\",[30,3]],[16,\"checked\",[30,4]],[17,5],[4,[38,0],[\"input\",[30,0,[\"onUpdate\"]]],null],[12],[13],[1,\"\\n  \"],[8,[30,6],null,null,null],[1,\"\\n\"]],[\"@name\",\"@inputId\",\"@disabled\",\"@value\",\"&attrs\",\"@labelComponent\"],false,[\"on\"]]",
    "moduleName": "ember-validated-form/components/validated-input/types/checkbox.hbs",
    "isStrictMode": false
  });
  let CheckboxComponent = _exports.default = (_class = class CheckboxComponent extends _component2.default {
    onUpdate(event) {
      event.preventDefault();
      this.args.update(event.target.checked);
      this.args.setDirty();
    }
  }, _applyDecoratedDescriptor(_class.prototype, "onUpdate", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onUpdate"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, CheckboxComponent);
});