define("ember-validated-form/passed-or-default", ["exports", "@embroider/util", "@embroider/macros/es-compat2"], function (_exports, _util, _esCompat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = passedOrDefault;
  function passedOrDefault(componentName) {
    return function (target, property) {
      return {
        get() {
          return (0, _util.ensureSafeComponent)(this.args[property] ?? (0, _esCompat.default)(require({
            "isDefault": true,
            "isUikit": false,
            "isBootstrap": false,
            "scrollErrorIntoView": false,
            "error": "ember-validated-form/components/validated-input/error",
            "hint": "ember-validated-form/components/validated-input/hint",
            "label": "ember-validated-form/components/validated-input/label",
            "render": "ember-validated-form/components/validated-input/render",
            "button": "ember-validated-form/components/validated-button/button",
            "types/checkbox-group": "ember-validated-form/components/validated-input/types/checkbox-group",
            "types/checkbox": "ember-validated-form/components/validated-input/types/checkbox",
            "types/input": "ember-validated-form/components/validated-input/types/input",
            "types/radio-group": "ember-validated-form/components/validated-input/types/radio-group",
            "types/select": "ember-validated-form/components/validated-input/types/select",
            "types/textarea": "ember-validated-form/components/validated-input/types/textarea",
            "types/date": "ember-validated-form/components/validated-input/types/input"
          }[componentName])).default, this);
        }
      };
    };
  }
});