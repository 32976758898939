define("ember-select/components/select-dropdown-group", ["exports", "@ember/object", "@ember/utils", "ember-select/components/select-dropdown", "ember-select/templates/components/select-dropdown-group", "ember-select/utils/tree"], function (_exports, _object, _utils, _selectDropdown, _selectDropdownGroup, _tree) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _selectDropdown.default.extend({
    layout: _selectDropdownGroup.default,
    groups: null,
    list: null,
    didReceiveAttrs() {
      this._super(...arguments);

      // Tree built in extended component
      let groups = this.get('list');
      let list = (0, _tree.getDescendents)(groups);
      this.setProperties({
        list,
        groups
      });
    },
    options: (0, _object.computed)('token', 'model.[]', 'values.[]', function () {
      if (this.get('shouldFilter')) {
        this.filterModel();
      }
      return this.get('groups');
    }),
    setVisibility(list, token) {
      list.filter(el => (0, _utils.isPresent)((0, _object.get)(el, 'parentId'))).filter(el => (0, _object.get)(el, 'name').toString().toLowerCase().indexOf(token) > -1).forEach(el => {
        el.set('isVisible', true);

        // Mark parent visible
        list.filter(x => x.id === (0, _object.get)(el, 'parentId')).shift().set('isVisible', true);
      });
    },
    upDownKeys(selected, event) {
      let list = this.get('list').filterBy('isVisible').filter(el => (0, _utils.isPresent)((0, _object.get)(el, 'parentId')));
      this.move(list, selected, event.keyCode);
    }
  });
});