define("ember-cli-summernote-editor/templates/components/summernote-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "tHMsM3AF",
    "block": "[[[10,0],[14,0,\"summernote\"],[12],[13]],[],false,[]]",
    "moduleName": "ember-cli-summernote-editor/templates/components/summernote-editor.hbs",
    "isStrictMode": false
  });
});