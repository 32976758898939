define("ember-route-action-helper/helpers/route-action", ["exports", "@ember/array", "@ember/component/helper", "@ember/object", "@ember/application", "@ember/runloop", "@ember/debug", "ember-route-action-helper/-private/internals"], function (_exports, _array, _helper, _object, _application, _runloop, _debug, _internals) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function getCurrentInfos(router) {
    let routerLib = router._routerMicrolib || router.router;
    return {
      currentInfos: routerLib.currentRouteInfos || routerLib.currentHandlerInfos,
      mapBy: routerLib.currentRouteInfos && "route" || "handler"
    };
  }
  function getRoutes(router) {
    const {
      currentInfos,
      mapBy
    } = getCurrentInfos(router);
    return (0, _array.A)(currentInfos).mapBy(mapBy).reverse();
  }
  function getRouteWithAction(router, actionName) {
    let action;
    let handler = (0, _array.A)(getRoutes(router)).find(route => {
      let actions = route.actions || route._actions;
      action = actions[actionName];
      return typeof action === "function";
    });
    return {
      action,
      handler
    };
  }
  class RouteAction extends _helper.default {
    get router() {
      return (0, _application.getOwner)(this).lookup("router:main");
    }
    compute([actionName, ...params]) {
      let router = (0, _object.get)(this, "router");
      (false && !(router) && (0, _debug.assert)("[ember-route-action-helper] Unable to lookup router", router));
      let action = function (...invocationArgs) {
        let args = params.concat(invocationArgs);
        let {
          action,
          handler
        } = getRouteWithAction(router, actionName);
        (false && !(handler) && (0, _debug.assert)(`[ember-route-action-helper] Unable to find action ${actionName}`, handler));
        return (0, _runloop.join)(handler, action, ...args);
      };
      action[_internals.ACTION] = true;
      return action;
    }
  }
  _exports.default = RouteAction;
});