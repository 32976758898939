define("ember-cli-daterangepicker/components/date-range-picker", ["exports", "@ember/debug", "jquery", "@ember/component", "@ember/runloop", "@ember/utils", "@ember/object", "moment", "ember-cli-daterangepicker/templates/components/date-range-picker"], function (_exports, _debug, _jquery, _component, _runloop, _utils, _object, _moment, _dateRangePicker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const noop = function () {};
  var _default = _exports.default = _component.default.extend({
    layout: _dateRangePicker.default,
    classNameBindings: ['containerClass'],
    attributeBindings: ['start', 'end', 'serverFormat'],
    start: undefined,
    end: undefined,
    minDate: undefined,
    maxDate: undefined,
    timePicker: false,
    timePicker24Hour: false,
    timePickerSeconds: false,
    timePickerIncrement: undefined,
    showWeekNumbers: false,
    showDropdowns: false,
    linkedCalendars: false,
    datelimit: false,
    parentEl: 'body',
    format: 'MMM D, YYYY',
    serverFormat: 'YYYY-MM-DD',
    rangeText: (0, _object.computed)('start', 'end', function () {
      let format = this.get('format');
      let serverFormat = this.get('serverFormat');
      let start = this.get('start');
      let end = this.get('end');
      if (!(0, _utils.isEmpty)(start) && !(0, _utils.isEmpty)(end)) {
        return (0, _moment.default)(start, serverFormat).format(format) + this.get('separator') + (0, _moment.default)(end, serverFormat).format(format);
      }
      return '';
    }),
    opens: null,
    drops: null,
    separator: ' - ',
    singleDatePicker: false,
    placeholder: null,
    containerClass: "form-group",
    inputClass: "form-control",
    inputClasses: (0, _object.computed)('inputClass', function () {
      let inputClass = this.get('inputClass');
      return inputClass ? 'daterangepicker-input ' + inputClass : 'daterangepicker-input';
    }),
    buttonClasses: ['btn'],
    applyClass: null,
    cancelClass: null,
    labelClass: null,
    direction: 'ltr',
    ranges: {
      'Today': [(0, _moment.default)().startOf('day'), (0, _moment.default)().endOf('day')],
      'Yesterday': [(0, _moment.default)().subtract(1, 'days').startOf('day'), (0, _moment.default)().subtract(1, 'days').endOf('day')],
      'Last 7 Days': [(0, _moment.default)().subtract(7, 'days'), (0, _moment.default)()],
      'Last 30 Days': [(0, _moment.default)().subtract(30, 'days'), (0, _moment.default)()],
      'This Month': [(0, _moment.default)().startOf('month'), (0, _moment.default)().endOf('month')],
      'Last Month': [(0, _moment.default)().subtract(1, 'month').startOf('month'), (0, _moment.default)().subtract(1, 'month').endOf('month')]
    },
    daysOfWeek: _moment.default.weekdaysMin(),
    monthNames: _moment.default.monthsShort(),
    removeDropdownOnDestroy: false,
    cancelLabel: 'Cancel',
    applyLabel: 'Apply',
    customRangeLabel: 'Custom Range',
    showCustomRangeLabel: false,
    fromLabel: 'From',
    toLabel: 'To',
    hideAction: null,
    applyAction: null,
    cancelAction: null,
    autoUpdateInput: true,
    autoApply: false,
    alwaysShowCalendars: false,
    context: undefined,
    firstDay: 0,
    isInvalidDate: noop,
    isCustomDate: noop,
    // Init the dropdown when the component is added to the DOM
    didInsertElement() {
      this._super(...arguments);
      this.setupPicker();
    },
    didUpdateAttrs() {
      this._super(...arguments);
      this.setupPicker();
    },
    // Remove the hidden dropdown when this component is destroyed
    willDestroy() {
      this._super(...arguments);
      _runloop.run.cancel(this._setupTimer);
      if (this.get('removeDropdownOnDestroy')) {
        (0, _jquery.default)('.daterangepicker').remove();
      }
    },
    getOptions() {
      let momentStartDate = (0, _moment.default)(this.get('start'), this.get('serverFormat'));
      let momentEndDate = (0, _moment.default)(this.get('end'), this.get('serverFormat'));
      let startDate = momentStartDate.isValid() ? momentStartDate : undefined;
      let endDate = momentEndDate.isValid() ? momentEndDate : undefined;
      let momentMinDate = (0, _moment.default)(this.get('minDate'), this.get('serverFormat'));
      let momentMaxDate = (0, _moment.default)(this.get('maxDate'), this.get('serverFormat'));
      let minDate = momentMinDate.isValid() ? momentMinDate : undefined;
      let maxDate = momentMaxDate.isValid() ? momentMaxDate : undefined;
      let showCustomRangeLabel = this.get('showCustomRangeLabel');
      let options = this.getProperties('isInvalidDate', 'isCustomDate', 'alwaysShowCalendars', 'autoUpdateInput', 'autoApply', 'timePicker', 'buttonClasses', 'applyClass', 'cancelClass', 'singleDatePicker', 'drops', 'opens', 'timePicker24Hour', 'timePickerSeconds', 'timePickerIncrement', 'showWeekNumbers', 'showDropdowns', 'showCustomRangeLabel', 'linkedCalendars', 'dateLimit', 'parentEl');
      let localeOptions = this.getProperties('applyLabel', 'cancelLabel', 'customRangeLabel', 'daysOfWeek', 'direction', 'firstDay', 'format', 'fromLabel', 'monthNames', 'separator', 'toLabel');
      const defaultOptions = {
        locale: localeOptions,
        showCustomRangeLabel: showCustomRangeLabel,
        startDate: startDate,
        endDate: endDate,
        minDate: minDate,
        maxDate: maxDate
      };
      if (!this.get('singleDatePicker')) {
        options.ranges = this.get('ranges');
      }
      return {
        ...options,
        ...defaultOptions
      };
    },
    setupPicker() {
      _runloop.run.cancel(this._setupTimer);
      this._setupTimer = _runloop.run.scheduleOnce('afterRender', this, this._setupPicker);
    },
    _setupPicker() {
      this.$('.daterangepicker-input').daterangepicker(this.getOptions());
      this.attachPickerEvents();
    },
    attachPickerEvents() {
      this.$('.daterangepicker-input').on('apply.daterangepicker', (ev, picker) => {
        this.handleDateRangePickerEvent('applyAction', picker);
      });
      this.$('.daterangepicker-input').on('hide.daterangepicker', (ev, picker) => {
        this.handleDateRangePickerEvent('hideAction', picker);
      });
      this.$('.daterangepicker-input').on('cancel.daterangepicker', () => {
        this.handleDateRangePickerEvent('cancelAction', undefined, true);
      });
    },
    handleDateRangePickerEvent(actionName, picker, isCancel = false) {
      let action = this.get(actionName);
      let start;
      let end;
      if (!isCancel) {
        start = picker.startDate.format(this.get('serverFormat'));
        end = picker.endDate.format(this.get('serverFormat'));
      }
      if (action) {
        (false && !(typeof action === 'function') && (0, _debug.assert)(`${actionName} for date-range-picker must be a function`, typeof action === 'function'));
        this.sendAction(actionName, start, end, picker);
      } else {
        if (!this.isDestroyed) {
          this.setProperties({
            start,
            end
          });
        }
      }
    }
  });
});