define("ember-select/components/select-dropdown-option", ["exports", "@ember/component", "@ember/runloop", "ember-select/templates/components/select-dropdown-option"], function (_exports, _component, _runloop, _selectDropdownOption) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    layout: _selectDropdownOption.default,
    classNames: ['es-option'],
    classNameBindings: ['model.isSelected:es-highlight'],
    init() {
      this._super(...arguments);
      this.set('handleMouseEnter', (0, _runloop.bind)(this, () => this.hover(this.model)));
    },
    didInsertElement() {
      this._super(...arguments);
      this.element.addEventListener('mouseenter', this.handleMouseEnter);
    },
    willDestroyElement() {
      this._super(...arguments);
      this.element.removeEventListener('mouseenter', this.handleMouseEnter);
    },
    click() {
      this.select(this.model);
    }
  });
});