define("ember-validated-form/components/validated-input/types/radio-group", ["exports", "@ember/component", "@ember/object", "@glimmer/component", "@ember/template-factory"], function (_exports, _component, _object, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#each @options as |option i|}}
    {{#if (macroCondition (macroGetOwnConfig "isUikit"))}}
      {{#if (not-eq i 0)}}<br />{{/if}}
      <label
        class="uk-form-label
          {{if @isValid 'uk-text-success'}}
          {{if @isInvalid 'uk-text-danger'}}"
      >
        <input
          type="radio"
          class="uk-radio uk-margin-small-right"
          checked={{eq @value option.key}}
          value={{option.key}}
          name={{@name}}
          id="{{@inputId}}-{{i}}"
          disabled={{@disabled}}
          {{on "input" (fn this.onUpdate option.key)}}
          ...attributes
        />
        {{option.label}}
      </label>
    {{else if (macroCondition (macroGetOwnConfig "isBootstrap"))}}
      <div class="custom-control custom-radio">
        <input
          type="radio"
          class="custom-control-input
            {{if @isValid 'is-valid'}}
            {{if @isInvalid 'is-invalid'}}"
          checked={{eq @value option.key}}
          value={{option.key}}
          name={{@name}}
          id="{{@inputId}}-{{i}}"
          disabled={{@disabled}}
          {{on "input" (fn this.onUpdate option.key)}}
          ...attributes
        />
        <label
          class="custom-control-label"
          for="{{@inputId}}-{{i}}"
        >{{option.label}}</label>
      </div>
    {{else}}
      <label>
        <input
          type="radio"
          checked={{eq @value option.key}}
          value={{option.key}}
          name={{@name}}
          id="{{@inputId}}-{{i}}"
          disabled={{@disabled}}
          {{on "input" (fn this.onUpdate option.key)}}
          ...attributes
        />
        {{option.label}}
      </label>
    {{/if}}
  {{/each}}
  */
  {
    "id": "oqr/SAsX",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,1]],null]],null],null,[[[1,\"    \"],[10,\"label\"],[12],[1,\"\\n      \"],[11,\"input\"],[24,4,\"radio\"],[16,\"checked\",[28,[37,2],[[30,4],[30,2,[\"key\"]]],null]],[16,2,[30,2,[\"key\"]]],[16,3,[30,5]],[16,1,[29,[[30,6],\"-\",[30,3]]]],[16,\"disabled\",[30,7]],[17,8],[4,[38,3],[\"input\",[28,[37,4],[[30,0,[\"onUpdate\"]],[30,2,[\"key\"]]],null]],null],[12],[13],[1,\"\\n      \"],[1,[30,2,[\"label\"]]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[2,3]],null]],[\"@options\",\"option\",\"i\",\"@value\",\"@name\",\"@inputId\",\"@disabled\",\"&attrs\"],false,[\"each\",\"-track-array\",\"eq\",\"on\",\"fn\"]]",
    "moduleName": "ember-validated-form/components/validated-input/types/radio-group.hbs",
    "isStrictMode": false
  });
  let RadioGroupComponent = _exports.default = (_class = class RadioGroupComponent extends _component2.default {
    onUpdate(value, event) {
      event.preventDefault();
      this.args.update(value);
      this.args.setDirty();
    }
  }, _applyDecoratedDescriptor(_class.prototype, "onUpdate", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onUpdate"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, RadioGroupComponent);
});